(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:neoPrintCardTemplate
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="components">
   <file name="index.html">
   <neo-print-card-template></neo-print-card-template>
   </file>
   </example>
   *
   */
  angular
      .module('neo')
      .directive('neoPrintCardTemplate', neoPrintCardTemplate);

  function neoPrintCardTemplate() {
    return {
      restrict: 'E',
      scope: {
        cards: '='
      },
      templateUrl: 'directives/neo-print-card-template/neo-print-card-template-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: function ($scope) {
        var vm = this;
        vm.name = 'neoPrintCardTemplate';
        vm.cards = $scope.cards;
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
